
import Vue from '@/vueTyped';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

// In the native Android build, Capacitor has access to EXIF data that ExifReader doesn't,
// but on desktop web, Capacitor doesn't report _any_ EXIF data.
// So we'll make what we get from Capacitor available here, and still only emit a regular File.
// Might be related to: https://github.com/ionic-team/capacitor-plugins/issues/45
export const exifFromCapacitor: WeakMap<File, Photo['exif']> = new WeakMap();

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                "addImage": "Add an image",
                "addVideo": "Add a video",
                // "takePhoto": "Take a photo"
            },

            es: {
                "addImage": "Añadir una imagen",
                "addVideo": "Añadir un vídeo",
                // "takePhoto": "Toma una foto"
            },
        },
    },

    props: {
        text: {
            type: Boolean,
            default: true,
        },

        icon: {
            type: String,
            default: 'add_a_photo',
        },

        maxVideoDuration: {
            type: Number,
            default: 0, // `0` means images only.
        },

        maxFileSize: {
            type: Number,
            default: Infinity,
        },
    },

    data() {
        return {
            fileInputResetting: false,
            justEmitted: new Set() as Set<string>,
        };
    },

    methods: {
        formatBytes(bytes: number): string {
            // Via https://stackoverflow.com/a/42408230
            const step = bytes > 0 ? Math.floor((Math.log2(bytes) / 10)) : 0;
            const count = (bytes / Math.pow(1024, step)).toFixed(1);
            const rank = ['B', 'KB', 'MB', 'GB', 'TB'][step] ?? '??';
            return `${count} ${rank}`;
        },

        async handleInputChange(event: InputEvent) {
            const files = Array.from((event.target as HTMLInputElement).files as FileList);
            const tooLongFiles: File[] = [];
            const tooBigFiles: File[] = [];

            for (const file of files) {
                if (file.type.startsWith('video/')) {
                    const duration = await this.getVideoDuration(file);
                    if (duration > this.maxVideoDuration) {
                        tooLongFiles.push(file);
                    }
                }

                if (file.size > this.maxFileSize) {
                    tooBigFiles.push(file);
                }
            }

            if (tooLongFiles.length > 0) {
                alert([
                    `Longer than the maximum of ${this.maxVideoDuration} seconds:`,
                    ...tooLongFiles.map(file => file.name),
                ].join('\n'))
                return;
            } if (tooBigFiles.length > 0) {
                alert([
                    `Larger than the maximum of ${this.formatBytes(this.maxFileSize)}:`,
                    ...tooBigFiles.map(file => `${file.name} (${this.formatBytes(file.size)})`),
                ].join('\n'))
                return;
            }

            this.$emit('input', files);

            this.fileInputResetting = true;
            await this.$nextTick();
            this.fileInputResetting = false;
        },

        async getVideoDuration(file: File): Promise<number> {
            return new Promise(resolve => {
                const video = document.createElement('video');
                const url = URL.createObjectURL(file);
                video.ondurationchange = () => {
                    resolve(video.duration);
                    URL.revokeObjectURL(url);
                };
                video.src = url;
            });
        },

        async handleClick() {
            const { dataUrl, exif } = await Camera.getPhoto({
                webUseInput: true,
                source: CameraSource.Prompt,
                presentationStyle: 'popover',
                resultType: CameraResultType.DataUrl,
            });

            if (dataUrl) {
                if (this.justEmitted.has(dataUrl)) {
                    return;
                }

                try {
                    // `getPhoto` seems to resolve multiple times if it gets interrupted,
                    // so let's debounce to prevent emitting duplicates.
                    this.justEmitted.add(dataUrl);

                    const randomName = Math.random().toString(16).split('.')[1];
                    const file = await this.dataUrlToFile(dataUrl, randomName);

                    if (exif) {
                        exifFromCapacitor.set(file, exif);
                    }

                    this.$emit('input', [file]);
                } catch (error) {
                    console.error(error);
                } finally {
                    setTimeout(() => this.justEmitted.delete(dataUrl), 100);
                }
            }
        },

        async dataUrlToFile(dataUrl: string, name: string): Promise<File> {
            const mimeType = dataUrl.split(';')[0].split(':')[1];
            const extension = mimeType.split('/')[1];

            return await fetch(dataUrl)
                .then(response => response.arrayBuffer())
                .then(buffer => new File([buffer], `${name}.${extension}`, { type: mimeType }));
        }
    },
});
